<template>
<div>
    <v-btn
        v-if="optimiseJob.job_status !== 'COMPLETE'"
        color="primary"
        small
        depressed
        :disabled="optimising"
        @click="optimise"
    >
        {{ optimiseJob.job_status }}
        <v-progress-circular v-if="optimising" class="ml-1"  size="12" width="2" indeterminate />
    </v-btn>
    <v-btn
        v-if="jobService.validOutput(optimiseJob, 'OPTIMIZED_MEDIA')"
        color="primary"
        small
        depressed
        :href="jobService.outputByType(optimiseJob, 'OPTIMIZED_MEDIA').download_url"
        target="_blank"
        v-text="'Download'"
    />
</div>
</template>

<script>
import jobService from '@/services/jobs'

export default {
    name: "OptimiseAction",
    props: {
        filename: {
            type: String,
            required: true
        },
        content_type: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        }
    },
    data: () => ({
        jobService,
        optimiseJob: {
            job_status: 'OPTIMISE'
        },
        interval: null,
        optimising: false
    }),
    methods: {
        jobComplete() {
            return jobService.isComplete(this.optimiseJob)
        },
        async checkJobStatus() {
            if ( !jobService.isComplete(this.optimiseJob) ) {
                const jobResponse = await jobService.get(this.optimiseJob.id)
                this.optimiseJob = Object.assign({}, jobResponse)
            } else {
                this.optimising = false
                clearInterval(this.interval)
            }
        },
        async optimise () {
            this.optimising = true
            const createJob = await jobService.create(
                { 
                    name: this.filename, 
                    type: this.content_type 
                },
                'OPTIMIZE_MEDIA',
                undefined,
                this.id
            )
            this.optimiseJob = await jobService.get(createJob.job_id)
            this.buttonText = this.optimiseJob.job_status
            this.interval = setInterval(this.checkJobStatus, 2000)
        }
    },
    beforeDestroy () {
        clearInterval(this.interval)
    }
}
</script>
